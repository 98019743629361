import React, { useState } from "react";
import {
  Filter,
  List,
  Datagrid,
  NumberField,
  TextField,
  useQueryWithStore,
  usePermissions,
  DateField,
  SelectArrayInput,
  SelectField,
} from "react-admin";

import {
  useMediaQuery,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { DateInput } from "react-admin-date-inputs";
import { parseISO, formatISO, subDays } from "date-fns";
import { paymentTypeList } from "./common";
import ListActions from "./components/extractListActions";
import TypeField from "./components/TypeField";
import { PermissionBlock } from "../../services/permissions/permissionBlock";
import { extractExport } from "../exports/index";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const ExtractList = (props) => {
  const classes = useStyles();
  const [dates, setDates] = useState({
    created_from: formatISO(subDays(new Date(), 1)),
    created_to: formatISO(new Date()),
  });
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const ExtractFilter = (props) => {
    return (
      <Filter {...props}>
        <DateInput
          label="Data Início"
          source="created_from"
          providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
          options={{ format: "dd/MM/yyyy" }}
          initialvalue={new Date()}
          parse={(value) => {
            let final = document.getElementById("created_to").value.split("/");
            let dateFinal = `${final[2]}-${final[1]}-${final[0]}`;
            setDates({
              created_from: formatISO(parseISO(value)),
              created_to: formatISO(parseISO(dateFinal)),
            });
            return value ? formatISO(parseISO(value)) : null;
          }}
          style={{ marginTop: 40 }}
          alwaysOn
        />
        <DateInput
          label="Data Fim"
          source="created_to"
          providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
          options={{ format: "dd/MM/yyyy" }}
          parse={(value) => {
            let init = document.getElementById("created_from").value.split("/");
            let dateInit = `${init[2]}-${init[1]}-${init[0]}`;
            setDates({
              created_from: formatISO(parseISO(dateInit)),
              created_to: formatISO(parseISO(value)),
            });
            return value ? formatISO(parseISO(value)) : null;
          }}
          alwaysOn
        />
        <SelectArrayInput
          label="Tipos"
          source="type"
          choices={paymentTypeList}
          style={{ minWidth: 160 }}
        />
      </Filter>
    );
  };

  const { loading } = useQueryWithStore({
    type: "getList",
    resource: "extract",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: dates,
    },
  });

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "extract");

    if (found !== "extract") {
      return <PermissionBlock name={"Extrato"} />;
    }
  }

  return (
    <List
      {...props}
      filters={<ExtractFilter />}
      filterDefaultValues={{
        created_from: formatISO(subDays(new Date(), 1)),
        created_to: formatISO(new Date()),
      }}
      title="Listar Extrato de Vendas"
      bulkActionButtons={false}
      actions={<ListActions />}
      exporter={extractExport}
    >
      {isSmall ? (
        loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Datagrid>
            <DateField label="Data " source="date" sortable={false} />
            <NumberField
              source="new_fee"
              label="Taxa"
              options={{ style: "currency", currency: "BRL" }}
              sortable={false}
            />
            <NumberField
              source="new_amount"
              label="Valor Líquido"
              options={{ style: "currency", currency: "BRL" }}
              sortable={false}
            />
          </Datagrid>
        )
      ) : loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Datagrid>
          <SelectField
            label="Tipo"
            source="type"
            choices={paymentTypeList}
            optionText={<TypeField />}
            sortable={false}
          />
          <TextField source="id" label="ID da Transação" sortable={false} />
          <DateField label="Data " source="date" sortable={false} />
          <TextField
            label="Descrição da Transação"
            source="description"
            sortable={false}
          />
          {/* <NumberField
            source="new_fee"
            label="Taxa"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          /> */}
          {/* <NumberField
            source="new_gross_amount"
            label="Valor da Transação"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          /> */}
          <NumberField
            source="new_amount"
            label="Valor Líquido"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />
          <NumberField
            source="new_current_balance"
            label="Saldo"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />
        </Datagrid>
      )}
    </List>
  );
};
