import { downloadCSV } from "react-admin";

import { format } from "date-fns";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const status = {
  succeeded: "Aprovada",
  canceled: "Cancelada",
  pending: "Pendente",
  failed: "Falhou",
  pre_authorized: "Pre-autorizada",
  reversed: "Revertida",
  refunded: "Estornada",
  dispute: "Em Disputa",
  charged_back: "Charged Back",
};

const paymentType = {
  credit: "Credito",
  debit: "Debito",
  boleto: "Boleto",
  pix: "Pix",
  nupay: "NuPay",
};

const removeAccentsAndUpperCase = (text) => {
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
  text = text.replace(new RegExp("[Ç]", "gi"), "c");
  text = text.toUpperCase();
  return text;
};

const processParcels = (parcel) => {
  if (parcel === 1) {
    return 'a vista';
  } else if (parcel) {
    return `${parcel}x`;
  }
  return '';
}

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    const taxa = post.fees; 
    const aReceber = (parseFloat(post.amount) - parseFloat(taxa)).toFixed(2);
    post.Data = format(new Date(post.created_at), "dd/MM/yyyy");
    post.Status = status[post.status];
    post.Pagamento = `${paymentType[post.payment_method.payment_type]} ${processParcels(post.payment_method.number_installments)}`;
    post.Valor = `R$ ${post.amount}`;
    post.Taxa = `R$ ${taxa}`;
    post.Receber = `R$ ${aReceber}`;
    post.ID_Transacao = post.id;
    post.Referencia = post.reference_id ? post.reference_id : post.id;
    post.Serial_POS = post.term_serial_number;
    post.Titular_Cartao =
      post.payment_method.card && post.payment_method.card.holder_name
        ? removeAccentsAndUpperCase(post.payment_method.card.holder_name)
        : "";

    return post;
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      "Data",
      "Status",
      "Pagamento",
      "Valor",
      "Taxa",
      "Receber",
      "ID_Transacao",
      "Referencia",
      "Serial_POS",
      "Titular_Cartao",
    ],
  });
  downloadCSV(csv, "Vendas");
};

export default exporter;
