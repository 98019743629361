import React, { useState } from "react";

import {
  Show,
  SimpleShowLayout,
  FunctionField,
  DateField,
  TextField,
  NumberField,
  SelectField,
} from "react-admin";

import { statusList } from "./common";
import { StatusField } from "./components";
import { renderPaymentMethod } from "./components/PaymentMethodField";
import { renderBilletGenerationMethod } from "./components/BilletGenerationField";
import { renderCardMethod } from "./components/CardField";
import PaymentIcon from "@material-ui/icons/Payment";

const TransactionTitle = () => <span>Detalhes da Venda</span>;

export const TransactionsShow = (props) => {
  const [name, setName] = useState("");
  const [display, setDisplay] = useState("flex");
  const PaymentName = ({ payment_method }) => {
    if (['boleto', 'pix', 'nupay'].indexOf(payment_method.payment_type) === -1) {
      setName(payment_method.card.card_brand);
    } else {
      setDisplay("none");
    }
  };

  return (
    <Show title={<TransactionTitle />} {...props}>
      <SimpleShowLayout>
        <DateField source="created_at" label="Data da Venda" showTime={true} />
        <FunctionField
          label="Forma de Pagamento"
          render={(record) => renderPaymentMethod(record)}
        />
        <SelectField
          source="status"
          choices={statusList}
          optionText={<StatusField />}
        />
        <TextField source="description" label="Descrição" />
        <NumberField
          source="amount"
          label="Valor da Venda"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          source="fees"
          label="Taxas"
          options={{ style: "currency", currency: "BRL" }}
          sortable={false}
        />

        <FunctionField
          label="Valor a Receber"
          textAlign="right"
          render={({ amount, fees }) =>
            new Intl.NumberFormat("pt-br", {
              style: "currency",
              currency: "BRL",
            }).format(amount - fees)
          }
        />
        <FunctionField
          label={() => ""}
          render={(record) => renderBilletGenerationMethod(record)}
        />
        <div style={{ display, alignItems: "center" }}>
          <PaymentIcon />
          <strong>{name}</strong>
        </div>
        <FunctionField
          label={() => ""}
          render={(record) => {
            PaymentName(record);
            return renderCardMethod(record);
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};
