import React, { useState } from "react";
import {
  Filter,
  List,
  SimpleList,
  Datagrid,
  NumberField,
  DateField,
  SelectField,
  FunctionField,
  SelectArrayInput,
  TextInput,
  TextField,
  useQueryWithStore,
  usePermissions,
} from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { DateInput } from "react-admin-date-inputs";
import { startOfDay, endOfDay, parseISO, formatISO, subDays } from "date-fns";
import { useMediaQuery } from "@material-ui/core";
import { statusList, paymentTypeList } from "./common";
import { PaymentMethodField, StatusField } from "./components";
import { renderPaymentMethod } from "./components/PaymentMethodField";
import ListActions from "./components/transactionsListActions";
import { transactionsExport } from "../exports/index";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const TransactionsList = (props) => {
  const classes = useStyles();
  const [dates, setDates] = useState({
    created_from: formatISO(startOfDay(subDays(new Date(), 1))),
    created_to: formatISO(endOfDay(new Date())),
  });
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const TransactionsFilter = (props) => (
    <Filter {...props}>
      <DateInput
        label="Data Início"
        source="created_from"
        providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
        options={{ format: "dd/MM/yyyy" }}
        initialvalue={new Date()}
        parse={(value) => {
          let final = document.getElementById("created_to").value.split("/");
          let dateFinal = `${final[2]}-${final[1]}-${final[0]}`;
          setDates({
            created_from: formatISO(startOfDay(parseISO(value))),
            created_to: formatISO(endOfDay(parseISO(dateFinal))),
          });
          return value ? formatISO(parseISO(value)) : null;
        }}
        style={{ marginTop: 40 }}
        alwaysOn
      />
      <DateInput
        label="Data Fim"
        source="created_to"
        providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
        options={{ format: "dd/MM/yyyy" }}
        parse={(value) => {
          let init = document.getElementById("created_from").value.split("/");
          let dateInit = `${init[2]}-${init[1]}-${init[0]}`;
          setDates({
            created_from: formatISO(startOfDay(parseISO(dateInit))),
            created_to: formatISO(endOfDay(parseISO(value))),
          });
          return value ? formatISO(parseISO(value)) : null;
        }}
        alwaysOn
      />
      <DateInput
        label="Data Aprovação Inicial"
        source="approved_from"
        providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
        options={{ format: "dd/MM/yyyy" }}
        parse={(value) =>
          value ? formatISO(startOfDay(parseISO(value))) : null
        }
        clearable
      />
      <DateInput
        label="Data Aprovação Final"
        source="approved_to"
        providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
        options={{ format: "dd/MM/yyyy" }}
        parse={(value) => (value ? formatISO(endOfDay(parseISO(value))) : null)}
        clearable
      />
      <SelectArrayInput
        label="Status"
        source="status"
        choices={statusList}
        style={{ minWidth: 100 }}
      />
      <SelectArrayInput
        label="Forma de Pagamento"
        source="payment_type"
        choices={paymentTypeList}
        style={{ minWidth: 160 }}
      />
      <TextInput label="Descritivo" source="statement_descriptor" clearable />
      <TextInput label="Referência" source="reference_id" clearable />
      <TextInput label="Serial POS" source="serial_pos" clearable />
    </Filter>
  );

  const { loading } = useQueryWithStore({
    type: "getList",
    resource: "transactions",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "created_at", order: "DESC" },
      filter: dates,
    },
  });

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "transactions");

    if (found !== "transactions") {
      return <PermissionBlock name={"Vendas"} />;
    }
  }

  return (
    <List
      {...props}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<TransactionsFilter />}
      filterDefaultValues={{
        created_from: formatISO(startOfDay(subDays(new Date(), 1))),
        created_to: formatISO(endOfDay(new Date())),
      }}
      title="Listar Vendas"
      exporter={transactionsExport}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      {isSmall ? (
        loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <SimpleList
            primaryText={(record) => renderPaymentMethod(record)}
            secondaryText={(record) =>
              new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(record.amount)
            }
            tertiaryText={(record) => {
              new Date(record.created_at).toLocaleDateString();
            }}
            linkType="show"
          />
        )
      ) : loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Datagrid rowClick="show">
          <DateField source="created_at" label="Data" showTime={true} />
          <SelectField
            source="status"
            choices={statusList}
            optionText={<StatusField />}
            sortable={false}
          />
          <PaymentMethodField label="Forma de Pagamento" />

          <NumberField
            source="amount"
            label="Valor da Venda"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />

          <FunctionField
            label="Taxas"
            textAlign="right"
            render={(record) => {
              return new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(
                record.fees
              );
            }}
          />

          <FunctionField
            label="Valor a Receber"
            textAlign="right"
            render={(record) => {
              return new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(
                record.payment_method.payment_type === "pix"
                  ? record.amount
                  : record.amount - record.fees
              );
            }}
          />
          <TextField label="Descritivo" source="statement_descriptor" />
          <TextField label="Referência" source="reference_id" />
        </Datagrid>
      )}
    </List>
  );
};
