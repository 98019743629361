import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

import { url as apiUrl, getDefaultHeaders } from "../config/api";

import {
  accountBalanceProvider,
  salesPerTypeProvider,
  salesProvider,
  netValueProvider,
  transferTransactionsProvider,
} from "../providers/index";

const httpClient = fetchUtils.fetchJson;

export default {
  /**
   * @param resource
   * @param pagination
   * @param sort
   * @param filter
   * @returns {Promise<{total: number, data: *}>}
   */
  getList: async (resource, { pagination, sort, filter }) => {
    const { page, perPage } = pagination;
    const { field, order } = sort;

    if (resource === "resumo" || resource === "seller_plan") {
      return {
        data: [],
        total: 1,
      };
    }

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage]),
      filter: JSON.stringify(filter),
    };

    const { json = [] } = await httpClient(
      `${apiUrl}/${resource}?${stringify(query)}`,
      { headers: getDefaultHeaders(), credentials: "include" }
    );

    return {
      data: json.items || [],
      total: json.total,
    };
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  getOne: async (resource, params) => {
    const { json: data = {} } = await httpClient(
      `${apiUrl}/${resource}/${params.id}`,
      {
        headers: getDefaultHeaders(),
        credentials: "include",
      }
    );
    return { data };
  },

  getMany: async (resource, params) => {
    const promises = params.ids.map((item) =>
      httpClient(`${apiUrl}/${resource}/${item}`, {
        headers: getDefaultHeaders(),
        credentials: "include",
      })
    );
    const records = (await Promise.all(promises).then((res) => res)).map(
      ({ json: data = {} }) => data
    );
    return { data: records };
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  update: async (resource, params) => {
    const { id, ...body } = params.data;
    const { json } = await httpClient(`${apiUrl}/${resource}/${id}`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: getDefaultHeaders(),
      credentials: "include",
    });
    return { data: json };
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<{data: {id: *}}>}
   */
  create: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: getDefaultHeaders(),
      credentials: "include",
    });
    return { data: json };
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  delete: async (resource, params) => {
    await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: getDefaultHeaders(),
    });
    return { data: {} };
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {Promise<{data: []}>}
   */
  deleteMany: async (resource, params) => {
    await Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          headers: getDefaultHeaders(),
          credentials: "include",
        })
      )
    );
    return { data: {} };
  },
  getAccountBalance: async (resource, params) => {
    const { data } = await accountBalanceProvider.getAccountBalance().then(
      (result) => result,
      (error) => error
    );
    return { data: data || [] };
  },
  getSummary: async (resource, params) => {
    if (params.filter.created_from && params.filter.created_to) {
      const query = {
        created_from: params.filter.created_from,
        created_to: params.filter.created_to,
      };

      const { data: dataPerType } = await salesPerTypeProvider
        .getSalesPerType(stringify(query))
        .then(
          (result) => result,
          (error) => error
        );

      const { data: dataSales } = await salesProvider
        .getSales(stringify(query))
        .then(
          (result) => result,
          (error) => error
        );

      const { data: dataNetValue } = await netValueProvider
        .getNetValue(stringify(query))
        .then(
          (result) => result,
          (error) => error
        );

      const dataTotal = {
        dataSales: dataSales.result,
        dataPerType: dataPerType.result,
        dataNetValue: dataNetValue.result,
      };

      return { data: dataTotal || [] };
    }
    return { data: null };
  },
  getTransferTransactions: async (resource, params) => {
    const { data } = await transferTransactionsProvider
      .getTransferTransactions(params.transferId)
      .then(
        (result) => result,
        (error) => error
      );

    return { data: data || [] };
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  updateStatus: async (resource, params) => {
    const { id, ...body } = params.data;
    const { json } = await httpClient(`${apiUrl}/${resource}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: getDefaultHeaders(),
      credentials: "include",
    });
    return { data: json };
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  findBarcode: async (barCode) => {
    const result = await httpClient(
      `${apiUrl}/payment/boleto/barcode/${barCode}`,
      {
        method: "GET",
        headers: getDefaultHeaders(),
        credentials: "include",
      }
    )
      .then((data) => data)
      .catch((error) => error);

    return { data: result };
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  findSellerPlan: async () => {
    const result = await httpClient(
      `${apiUrl}/seller_plan`,
      {
        method: "GET",
        headers: getDefaultHeaders(),
        credentials: "include",
      }
    )
      .then((data) => data)
      .catch((error) => error);

    return { data: result };
  },
};
