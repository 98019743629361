import React from "react";
import { FunctionField } from "react-admin";

export const renderCardMethod = (record) => {
  const { payment_method, id, reference_id } = record;
  const style = {
    class:
      "MuiFormLabel-root MuiInputLabel-root RaLabeled-label-197 MuiInputLabel-animated MuiInputLabel-shrink",
    marginTop: 10,
  };
  if (['boleto', 'pix', 'nupay'].indexOf(payment_method.payment_type) === -1) {
    return (
      <div>
        <label class={style.class}>Portador do Cartão:</label>
        <label>{payment_method.card.holder_name}</label>
        <label style={{ marginTop: style.marginTop }} class={style.class}>
          N° Cartão:
        </label>
        <label>{payment_method.card.card_number}</label>
        <label style={{ marginTop: style.marginTop }} class={style.class}>
          Validade:
        </label>
        <label>
          {payment_method.card.expiration_month +
            "/" +
            payment_method.card.expiration_year}
        </label>
        <label style={{ marginTop: style.marginTop }} class={style.class}>
          ID da transação:
        </label>
        <label>{id}</label>
        <label style={{ marginTop: style.marginTop }} class={style.class}>
          Reference ID:
        </label>
        <label>{reference_id}</label>
      </div>
    );
  }
};

export const CardField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderCardMethod(record);
    }}
  />
);
