import React from "react";

import { Admin, Resource } from "react-admin";
import Cookies from 'universal-cookie';
import { Login, theme, MyLayout, Dashboard } from "./layout/index";

import i18nProvider from "./lang/ptLangProvider";
import { authProvider, dataProvider } from "./providers/index";

import {
  SalePlanList,
  SalePlanShow,
  SalePlanCreate,
  SalePlanEdit,
} from "./pages/salePlan/index";

import {
  RecurringPurchaseList,
  RecurringPurchaseShow,
  RecurringPurchaseCreate,
} from "./pages/recurringPurchase/index";

// import {
//   SaleByLinkList,
//   SaleByLinkShow,
//   SaleByLinkCreate,
// } from "./pages/saleByLink/index";

import {
  TransactionsList,
  TransactionsCreate,
  TransactionsShow,
} from "./pages/transactions/index";

import { TedCreate } from "./pages/ted/index";

// import { DonateList, DonateShow, DonateCreate } from "./pages/donate/index";

import { TransfersList, TransferShow } from "./pages/transfers/index";
import { FutureTransferList } from "./pages/futureTransfer/index";
import { SellerPlanList } from "./pages/sellerPlan/index";
import { BankAccountList, BankAccountCreate } from "./pages/bankAccount/index";
import { PaymentBoletoCreate } from "./pages/paymentBoleto/index";
import { ExtractList } from "./pages/extract/index";

const App = () => {
  const cookies = new Cookies();
  const cookiesView = cookies.get('token_firebase');
  if (cookiesView) {
    alert(cookiesView);
  }

  return (
    <Admin
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      loginPage={Login}
      layout={MyLayout}
      theme={theme}
      dashboard={Dashboard}
      dataProvider={dataProvider}
    >
      <Resource
        name="transfers"
        list={TransfersList}
        show={TransferShow}
        options={{ label: "Transferências" }}
      />
      <Resource
        name="transactions"
        list={TransactionsList}
        show={TransactionsShow}
        options={{ label: "Vendas" }}
      />
      <Resource
        name="transactionsCreate"
        list={TransactionsCreate}
        options={{ label: "Nova Venda" }}
      />
      <Resource
        name="sale_plan"
        list={SalePlanList}
        show={SalePlanShow}
        create={SalePlanCreate}
        edit={SalePlanEdit}
        options={{ label: "Planos de Assinatura" }}
      />
      <Resource
        name="recurring_purchase"
        list={RecurringPurchaseList}
        show={RecurringPurchaseShow}
        create={RecurringPurchaseCreate}
        options={{ label: "Compra Recorrente" }}
      />
      {/*<Resource*/}
      {/*  name="sale_link"*/}
      {/*  list={SaleByLinkList}*/}
      {/*  show={SaleByLinkShow}*/}
      {/*  create={SaleByLinkCreate}*/}
      {/*  options={{ label: "Venda por Link" }}*/}
      {/*/>*/}
      <Resource
        name="future/transfer"
        list={FutureTransferList}
        options={{ label: "Transferências Futuras" }}
      />
      <Resource
        name="payment/boleto"
        list={PaymentBoletoCreate}
        options={{ label: "Pagar Conta" }}
      />
      {/*<Resource*/}
      {/*  name="donate"*/}
      {/*  list={DonateList}*/}
      {/*  show={DonateShow}*/}
      {/*  create={DonateCreate}*/}
      {/*  options={{ label: "Doação por Link" }}*/}
      {/*/>*/}
      <Resource
        name="ted"
        list={TedCreate}
        options={{ label: "TED" }}
      />
      <Resource
        name="extract"
        list={ExtractList}
        options={{ label: "Extrato" }}
      />
      <Resource
        name="seller_plan"
        list={SellerPlanList}
        options={{ label: "Plano de Taxas" }}
      />
      <Resource
        name="bank_account"
        list={BankAccountList}
        create={BankAccountCreate}
        options={{ label: "Conta Bancária" }}
      />
    </Admin>
  );
};

export default App;
