import React from "react";
import barcode from "../../../images/barcode.png";
import mastercard from "../../../images/mastercard.png";
import hipercard from "../../../images/hipercard.png";
import visa from "../../../images/visa.png";
import elo from "../../../images/elo-card.png";
import amex from "../../../images/amex.png";
import aura from "../../../images/aura.png";
import alelo from "../../../images/alelo.png";
import maestro from "../../../images/maestro.png";
import ticket from "../../../images/ticket.png";
import banescard from "../../../images/banescard.png";
import visa_electron from "../../../images/visa_electron.png";
import dinersclub from "../../../images/dinersclub.png";
import hiper from "../../../images/hiper.png";
import discover from "../../../images/discover.png";
import pix from "../../../images/icon_pix.png";
import nupay from "../../../images/nupay.svg";
import { FunctionField } from "react-admin";

const styles = {
  formPayment: { display: "flex", alignItems: "center" },
  img_barcode: { height: 30, width: 45 },
  img_pix: { height: 20, width: 22 },
  img_nupay: { height: 47, width: 70 },
  p: { marginLeft: 5 },
};

export const renderPaymentMethod = ({ payment_method }) => {
  if (payment_method.payment_type === "boleto") {
    return (
      <div style={styles.formPayment}>
        <img style={styles.img_barcode} src={barcode} alt="Boleto Bancário" />
        <p style={styles.p}>Boleto Bancário</p>
      </div>
    );
  } else if (payment_method.payment_type === "pix") {
    return (
      <div style={styles.formPayment}>
        <img style={styles.img_pix} src={pix} alt="PIX" />
        <p style={styles.p}>PIX</p>
      </div>
    );
  } else if (payment_method.payment_type === "nupay") {
    return (
        <div style={styles.formPayment}>
          <img style={styles.img_nupay} src={nupay} alt="NuPay" />
        </div>
    );
  } else {
    const { number_installments, card, payment_type } = payment_method;
    const { card_brand } = card;
    const img_card = {};
    let propsCard = {};

    switch (card_brand) {
      case "MasterCard":
        img_card.brand = mastercard;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Hipercard":
        img_card.brand = hipercard;
        img_card.height = 30;
        img_card.width = 50;
        img_card.marginLeft = -5;
        break;
      case "Visa":
        img_card.brand = visa;
        img_card.height = 40;
        img_card.width = 45;
        break;
      case "Elo":
        img_card.brand = elo;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "American Express":
        img_card.brand = amex;
        img_card.height = 40;
        img_card.width = 45;
        break;
      case "Aura":
        img_card.brand = aura;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Alelo":
        img_card.brand = alelo;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Maestro":
        img_card.brand = maestro;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Banescard":
        img_card.brand = banescard;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Ticket":
        img_card.brand = ticket;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Visa Electron":
        img_card.brand = visa_electron;
        img_card.height = 35;
        img_card.width = 45;
        break;
      case "Diners Club":
        img_card.brand = dinersclub;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Hiper":
        img_card.brand = hiper;
        img_card.height = 30;
        img_card.width = 45;
        break;
      case "Discover":
        img_card.brand = discover;
        img_card.height = 30;
        img_card.width = 45;
        break;

      default:
        break;
    }

    if (payment_type === "credit" && number_installments === 1) {
      propsCard = props(img_card, styles, "Crédito à Vista");
      return <PaymentCard {...propsCard} />;
    }

    if (payment_type === "debit") {
      propsCard = props(img_card, styles, "Débito à Vista");
      return <PaymentCard {...propsCard} />;
    }

    if (payment_type === "voucher") {
      propsCard = props(img_card, styles, "Voucher");
      return <PaymentCard {...propsCard} />;
    }

    propsCard = props(
      img_card,
      styles,
      `Crédito Parcelado ${number_installments}x`
    );
    return <PaymentCard {...propsCard} />;
  }
};

const PaymentCard = ({ img_card, styles, description }) => {
  let propsImg = props(img_card, description);
  return (
    <div style={styles.formPayment}>
      <Image {...propsImg} />
      <p style={styles.p}>{description}</p>
    </div>
  );
};

const props = (img_card, styles, description) => {
  return {
    img_card,
    description,
    styles,
  };
};

const Image = ({ img_card, description }) => (
  <img
    style={{
      height: img_card.height,
      width: img_card.width,
      marginLeft: img_card.marginLeft,
    }}
    src={img_card.brand}
    alt={description}
  />
);

export const PaymentMethodField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderPaymentMethod(record);
    }}
  />
);
